<template>
  <div class="container">
    <div class="invitaion_top" ref="invitaionTop">
      <div class="invitaion_router" @click="goBack">
        <img src="../assets/played_left.png" class="invitaion_back" alt="" />
        <span class="invitaion_title">消费记录</span>
      </div>
    </div>
    <div class="lecoin">
      <div class="lecoin_content" v-for="(item,i) in items" :key="i">
        <div class="lecoin_lece">
          <img src="../assets/dou.png" alt="" class="lecoin_img">
          <div class="lecoin_le">
            <span class="lecoin_le_tit">消费</span>
            <span class="lecoin_le_text" style="margin-bottom: 2px">(剩余糖豆: {{item.coinSum / 100}})</span>
            <span class="lecoin_le_text">{{item.date}}</span>
          </div>
        </div>
        <div class="lecoin_xh">
          <span class="lecoin_le_title">-{{item.coinatmChange / 100}}</span>
          <span class="lecoin_le_text">已完成</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { ksort } from "@/utils/ksort";
import { timeApi3 } from "@/utils/index";
import { askLecoinDetail } from "@/api";
export default {
  data() {
    return {
      items: []
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getData();
    });
  },
  mounted() {
    if (judgeBigScreen()) {
      this.$refs.invitaionTop.className = "invitaion_top invitaionTopAndroid";
    }
  },
  methods: {
    goBack() {
      // store.commit("tabbarIn");
      store.commit("incrementOut");
      this.$router.go(-1);
    },
    getData() {
      var params = {
        token: this.CYBoxUser.loginToken,
        time: Math.floor(Date.now() / 1000)
      }
      params.sign = ksort(params, 'qsdhajasfas123sq')
      askLecoinDetail(params).then(res => {
        if(res.code == 1) {
          // this.items = res.data
          var arr = []
          res.data.map(e => {
            e.date = timeApi3(e.time - 0)
            arr.push(e)
          })
          this.items = arr
        }
      })
    },
    goReceive(item) {
      
    },
  },
  computed: {
    ...mapGetters(["CYBoxUser"]),
  },
};
</script>
<style lang="stylus" scoped>
@import '../components/common.styl';

.invitaion_top {
  background: url('../assets/playedt.png') center;
  background-size: 100% 100%;
  width 100%
  height: calc(114px + constant(safe-area-inset-bottom));
  height: calc(114px + env(safe-area-inset-bottom));
  position: relative;
  display: flex;
  justify-content: center;
}

.invitaion_router {
  height: 42px;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  justify-content: center;
}

.invitaion_back {
  position: absolute;
  height: 20px;
  width: 12px;
  top: calc(12px + constant(safe-area-inset-bottom));
  top: calc(12px + env(safe-area-inset-bottom));
  left: 16px;
}

.invitaion_title {
  color: #fff;
  font-size: 20px;
  position: absolute;
  top: calc(10px + constant(safe-area-inset-bottom));
  top: calc(10px + env(safe-area-inset-bottom));
}
.lecoin {
  position relative
  top -20px
  left 2%
  margin-bottom: 50px;
}
.lecoin_content {
  display flex
  height 102px
  background #fff
  width 96%
  border-radius 5px
  justify-content: space-between;
  margin-bottom: 6px;
}
.lecoin_img {
  height 34px
  width 34px
  margin-left 10px
  margin-top 20px
}
.lecoin_lece {
  display: flex;
}
.lecoin_le {
  display flex
  flex-direction column
  margin-top 20px
  margin-left 10px
}
.lecoin_le_text {
  color #7c7c7c
  font-size: 14px;
}
.lecoin_xh {
  display flex
  flex-direction column
  align-items: center;
  justify-content: center;
  margin-right 20px
}
.lecoin_le_tit {
  font-size 18px
  margin-bottom 4px
}
.lecoin_le_title {
  font-size 18px
  margin-bottom 10px
}
</style>